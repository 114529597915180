import React from 'react';
import '../../styles/contact/Heading.css';

const Heading = () => {
  return (
    <div className='contactHeading'>
      <h1>Contact Me</h1>
    </div>
  );
};

export default Heading;
